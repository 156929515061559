import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    "input-style": {display:'none'},
    onKeypress: _ctx.onkeypress,
    modelValue: _ctx.codeValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.codeValue) = $event)),
    placeholder: "请输入条形码"
  }, null, 8, ["onKeypress", "modelValue"]))
}