
import { defineComponent,ref} from 'vue'
export default defineComponent({
    emits: ['barCode'],
    setup(props, context) {
            const codeValue=ref()
            const code=ref('')
            const lastTime=ref()
            const nextTime=ref()
            const lastCode=ref()
            const nextCode=ref<number>()
            const dtmainId=ref('') 
            const parseQRCode=(nowCode:any)=>{
                // if (code.length === 13) {
                //     // 处理自己的逻辑
                //     context.emit('barCode', code) //通知父组件
                // } else if (code.length === 23) {
                //     console.log('B类条码:' + code)
                // } else if (code.length === 0) {
                //     console.log('请输入条码！')
                // } else {
                //     alert('条码不合法：' + code)
                // }
                codeValue.value = nowCode
                context.emit('barCode', nowCode)
                code.value=""
                // console.log(code)
                // 发送网络请求
            }
            const onkeypress = (e:any) => {
                    if (window.event) { // IE
                        nextCode.value = e.keyCode
                    } else if (e.which) { // Netscape/Firefox/Opera
                        nextCode.value = e.which
                    }
                
                    if (e.which === 13) { // 键盘回车事件
                        if (code.value.length < 3) return // 扫码枪的速度很快，手动输入的时间不会让code的长度大于2，所以这里不会对扫码枪有效
                        parseQRCode(code.value) // 获取到扫码枪输入的内容，做别的操作
                        lastCode.value = ''
                        lastTime.value = ''
                        return
                    }


                    nextTime.value = new Date().getTime()
                    if (!lastTime.value && !lastCode.value) {
                        code.value = '' // 清空上次的条形码
                        code.value += e.key
                        //  console.log(e.key,'e.key')
                        // console.log('扫码开始---', code.value)
                    }
                    if (lastCode.value && lastTime.value && nextTime.value - lastTime.value > 500) { // 当扫码前有keypress事件时,防止首字缺失
                        code.value= e.key
                        // console.log('防止首字缺失。。。', code.value)
                    } else if (lastCode.value && lastTime.value) {
                        code.value += e.key
                        // console.log('扫码中。。。',code.value)
                    }
                    lastCode.value = nextCode.value
                    lastTime.value = nextTime.value
            }

    
        return{
            codeValue,
            code,
            lastTime,
            nextTime,
            lastCode,
            nextCode,
            dtmainId,
            onkeypress
        }
    }
})

